import { Form, Formik, FormikProps } from "formik";
import React, { useState } from "react";
import styled from "styled-components";
import theme from "../../../util/theme";
import { StyledField } from "../../ui/StyledField";
import { registerCoupon } from "../../../services/firebase";
import { mobileSize } from "../../../util/variables";
import { AppLoadingButton } from "../../ui";
import { navigate } from "gatsby";
import {
  web_acq_create_account_attempt,
  web_acq_create_account_failure,
  web_acq_create_account_success,
} from "../../../services/mixpanel/acquisition-events";
import { newUserSchema } from "../../../util/yup-schemas";
import { GTM_CUSTOM_EVENT } from "../../../util/types";
import { triggerCustomEvent } from "../../../services/google-tag-manager";

interface FormikValuesProps {
  email: string;
  password: string;
}

export const SpecialOfferAccountForm = ({ redirectLink }: { redirectLink: string }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: FormikValuesProps) => {
    const { email, password } = values;
    setLoading(true);
    web_acq_create_account_attempt("email");

    const response = await registerCoupon({
      email,
      password,
    });
    if (response.error) {
      web_acq_create_account_failure("email");
      alert(response.error);
      setLoading(false);
      return;
    }

    const { uid } = response.user;
    triggerCustomEvent(GTM_CUSTOM_EVENT.COMPLETE_REGISTRATION);
    web_acq_create_account_success("email");
    localStorage.setItem("email", email);
    localStorage.setItem("uid", uid);
    // for performance, 3rd party tracking/analytics has been moved to Prepayment page (/special-offer)
    navigate(redirectLink, {
      state: {
        uid,
        email,
      },
    });
    setLoading(false);
  };

  return (
    <Container>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={newUserSchema}
        onSubmit={handleSubmit}
      >
        {(formik: FormikProps<FormikValuesProps>) => (
          <StyledForm>
            <Title>Unlock the Future of Learning</Title>
            <FormContainer>
              <Title2>Create a Free Account</Title2>
              <StyledField name="email" type="email" />
              <StyledField value={formik.values.password} name="password" type="password" />
              <HideDiv showDesktop>
                <AppLoadingButton
                  disabled={
                    !formik.values.password ||
                    !!formik.errors.password ||
                    !formik.values.email ||
                    !!formik.errors.email
                  }
                  loading={loading}
                  type="submit"
                >
                  Continue
                </AppLoadingButton>
              </HideDiv>
            </FormContainer>
            <HideDiv showMobile>
              <AppLoadingButton
                disabled={
                  !formik.values.password ||
                  !!formik.errors.password ||
                  !formik.values.email ||
                  !!formik.errors.email
                }
                loading={loading}
                type="submit"
                style={{ border: `2px solid ${theme.WHITE_COLOR}` }}
              >
                Continue
              </AppLoadingButton>
            </HideDiv>
          </StyledForm>
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  @media ${mobileSize} {
    height: 100%;
  }
`;

const Title = styled.h1`
  font-size: 35px;
  font-weight: 500;
  font-family: ${theme.SECONDARY_FONT};
  color: ${theme.BLACK_COLOR};
  text-align: center;
  @media ${mobileSize} {
    font-size: 24px;
    /* font-weight: 700; */
  }
`;

const Title2 = styled.h2`
  margin-top: 0;
  margin-bottom: 36px;
  font-size: 24px;
  font-weight: 500;
  font-family: ${theme.SECONDARY_FONT};
  text-align: center;
  color: ${theme.BLACK_COLOR};
  @media ${mobileSize} {
    margin-bottom: 24px;
    font-size: 18px;
  }
`;

const StyledForm = styled(Form)`
  @media ${mobileSize} {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  width: 90vw;
  padding: 80px;
  margin: auto;
  margin-top: 31px;
  border-radius: 16px;
  background-color: #fafafa;
  box-shadow: 0px 1.58px 11px rgba(44, 39, 56, 0.14);

  @media ${mobileSize} {
    padding: 40px 20px;
    min-height: 300px;
    margin: 24px;
  }
`;

const HideDiv = styled.div<{ showDesktop?: boolean; showMobile?: boolean }>`
  display: ${props => (props.showDesktop ? "block" : "none")};
  /* padding: 0 24px; */
  @media ${mobileSize} {
    display: ${props => (props.showDesktop ? "none" : "block")};
    margin-top: 16px;
    width: 100%;
    padding: 0 24px;
  }
`;
